import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [data, setData] = useState(null);

  const handleButtonClick = async () => {
    try {
      // const response = await fetch('http://localhost:5000/api/Test');
      const response = await fetch('https://dev.ingress-weg-3pq.n4group.eu/api/Test');
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={handleButtonClick}>Fetch Data</button>
        {data && (
          <div>
            <h3>API Response:</h3>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
